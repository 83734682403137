<template>
  <div>
    <div class="vip-type-list" style="font-size:16x">
      <span>
{{this.$route.query.shopName}}-会员</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeSubNum(false)">＜</div>
          <div>{{yearMonth}}</div>
          <div class="box-right" @click="changeSubNum(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:16px">
        <span style="font-size:14px">
会员卡充值：{{vipCardAllMoney.toFixed(2)}}（充值金额：{{vipCardMoney.toFixed(2)}}   赠送金额：{{vipCardGieyMoney.toFixed(2)}}）    会员卡消费：{{vipCardAllOutMoney.toFixed(2)}}</span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="firstData" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <!-- <el-table-column prop="shopName" label="店铺名称" /> -->
          <el-table-column prop="yearMonth" label="时间" />
          <el-table-column prop="newMember" label="新增会员" />
          <el-table-column prop="membership" label="会员总数" />
          <el-table-column label="会员充值"> 
            <!-- prop="collect"  -->
            <el-table-column prop="memberRechargeGetMoney" label="会员卡充值"  width="120" />
            <el-table-column prop="realMoney" label="充值金额" />
            <el-table-column prop="handselMoney" label="赠送金额" />
            <el-table-column prop="rechargeNumber" label="会员卡充值人数" width="150" />
          </el-table-column>
          <el-table-column label="营业收入">
            <el-table-column prop="expendMoney" label="会员消费" />
            <el-table-column prop="consumeNumber" label="会员卡消费人数" width="150" />
          </el-table-column>
        </el-table>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
        >
          <el-table-column prop="dutyStartTime" label="班次开始时间" width="140"/>
          <el-table-column prop="dutyEndTime" label="班次结束时间"  width="140"/>
          <el-table-column prop="newMember" label="新增会员" />
          <el-table-column label="会员充值"> 
            <!-- prop="collect"  -->
            <el-table-column prop="memberRechargeGetMoney" label="会员卡充值"  width="120" />
            <el-table-column prop="realMoney" label="充值金额" />
            <el-table-column prop="handselMoney" label="赠送金额" />
            <el-table-column prop="rechargeNumber" label="会员卡充值人数" width="150" />
          </el-table-column>
          <el-table-column label="营业收入">
            <el-table-column prop="expendMoney" label="会员消费" />
            <el-table-column prop="consumeNumber" label="会员卡消费人数" width="150" />
          </el-table-column>
        </el-table>
      </div>
    </main>
    <Print rul='report/monthChainExportMemberStatistics.json' :add='"&yearMonth="+yearMonth+"&chainShopId="+this.$route.query.shopId'/>  </div>
</template>

<script>
import {ref, watch} from 'vue'
import {postData} from"@a"
import {useRoute} from 'vue-router'
export default {
  setup(){
    let route = useRoute()
    const dataOne = ref([])
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      style['text-align'] = 'center'
      return style
    }
    const cellStyle = ({row, column})=>{
      let style  = {}
      style['text-align'] = 'center'
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      if(column.label==="时间1" && row.shopName !== "合计1"){
        style.color = '#169BD5'
        style['text-decoration'] = 'underline'
        style['cursor'] = 'pointer'
      }
      if(column.label==="店铺名称"){
        style['font-weight'] = 600
      }
      return style
    }
    let currentYear = ref(new Date().getFullYear())
    let currentMonth = ref(new Date().getMonth()+1)
    let maxYear = currentYear.value
    let maxMonth = currentMonth.value
    let yearMonth = ref(maxYear + '-' + maxMonth)
    watch([currentYear, currentMonth], () => {
      yearMonth.value = currentYear.value + '-' + (Array(2).join(0) + currentMonth.value).slice(-2)
    })
    if(route.query.time){
      currentYear.value = parseInt(route.query.time.split('-')[0])
      currentMonth.value = parseInt(route.query.time.split('-')[1])
    }
    const changeSubNum = (val)=>{
      let month = currentMonth.value
      let year = currentYear.value
      if (val) {
        if (month == 12) {
          month = 1
          year += 1
        } else {
          month += 1
        }
      } else {
        if (month == 1) {
          month = 12
          year -= 1
        } else {
          month -= 1
        }
      }
      if (year >= maxYear && month >= maxMonth) {
        return
      }
      currentYear.value = year
      currentMonth.value = month
      infoData()
    }
    let firstData = ref([])
    let vipCardAllMoney = ref(0)
    let vipCardMoney = ref(0)
    let vipCardGieyMoney = ref(0)
    let vipCardAllOutMoney = ref(0)
    const infoData = async()=>{
      try{
        let param = {
          yearMonth:currentYear.value + '-' + (Array(2).join(0) + currentMonth.value).slice(-2),
          chainShopId:route.query.shopId
        }
        let res = await postData('shop/monthChainMemberStatistics.json',param)
        let mianData = await postData('shop/monthMemberBalanceDetail.json',param)
        firstData.value = mianData.data
        dataOne.value = res.data
        // firstData.value[0] = {
        //   shopName:'...',
        //   newMember : 0,
        //   memberRechargeGetMoney : 0,
        //   realMoney : 0,
        //   handselMoney : 0,
        //   rechargeNumber : 0,
        //   expendMoney : 0,
        //   consumeNumber : 0,
        // }
        vipCardAllMoney.value = 0
        vipCardMoney.value = 0
        vipCardGieyMoney.value = 0
        vipCardAllOutMoney.value = 0
        for(let i = 0;i<res.data.length;i++){
          vipCardAllMoney.value += Number(res.data[i].memberRechargeGetMoney)
          vipCardMoney.value += Number(res.data[i].realMoney)
          vipCardGieyMoney.value += Number(res.data[i].handselMoney)
          vipCardAllOutMoney.value += Number(res.data[i].expendMoney)
        }
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      yearMonth,
      dataOne,
      headerStyle,
      cellStyle,
      changeSubNum,
      firstData,
      vipCardAllMoney,
      vipCardMoney,
      vipCardGieyMoney,
      vipCardAllOutMoney,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>